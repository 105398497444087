.App {
  text-align: center;
  padding: 50px;
  background-color: #f8f8f8;
}

.App-header {
  margin: 0 auto;
  max-width: 800px;
}

/* If you'd like to override or add more styles, do it here */
.subtitle {
  margin-bottom: 10px;
  color: #555;
  font-size: 1rem;
}

.usage-note {
  margin-bottom: 20px;
  color: #666;
  font-size: 0.9rem;
}

.refresh-button {
  padding: 8px 16px;
  background-color: #337ab7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 30px; /* some spacing between button and ad content */
}

.refresh-button:hover {
  background-color: #286090;
}

.ad-container {
  text-align: left;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.ad-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ad-detail {
  display: flex;
  margin-bottom: 5px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  color: #555;
}

.ad-title {
  margin: 20px 0 20px 0;
  font-size: 1.25rem;
  color: #222;
}

.ad-image-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
  background: #fafafa;
}

.ad-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
